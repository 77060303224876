import { getHintUtils } from "@epic-web/client-hints";
import { clientHint as colorSchemeHint, subscribeToSchemeChange } from "@epic-web/client-hints/color-scheme";
import { clientHint as timeZoneHint } from "@epic-web/client-hints/time-zone";
import { invariant } from "@epic-web/invariant";
import { useRevalidator, useRouteLoaderData } from "@remix-run/react";
import * as React from "react";
import { type loader as rootLoader } from "~/root";

const hintsUtils = getHintUtils({
  theme: colorSchemeHint,
  timeZone: timeZoneHint,
});

export const { getHints } = hintsUtils;

export function useHints() {
  const requestInfo = useRequestInfo();
  return requestInfo.hints;
}

export function ClientHintCheck({ nonce }: { nonce: string }) {
  const { revalidate } = useRevalidator();
  React.useEffect(() => subscribeToSchemeChange(() => revalidate()), [revalidate]);

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
    />
  );
}

export function useRequestInfo() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  invariant(data?.requestInfo, "No requestInfo found in root loader");
  return data.requestInfo;
}
